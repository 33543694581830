@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900);
@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Features
7. Gallery
8. About
9. Testimonials
10. Booking
11. Blog
12. Footer
13. Responsive


******************************/

/***********
1. Fonts
***********/

/*********************************
2. Body and some general stuff
*********************************/
button, a{
	outline:none;
}

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	scroll-behavior: smooth;
	font-family: 'Raleway', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #FFFFFF;
	color: #a5a5a5;
}
div
{
	display: block;
	position: relative;
 box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Raleway', sans-serif;
	font-size: 15px;
	line-height: 2;
	font-weight: 500;
	/* color: #6d6d6d; */
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #1abc9c;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: rgba(0,0,0,.01);
}
p a:hover::after
{
	opacity: 0.2;
}
::-moz-selection
{
	background: rgba(255,163,123,1);
	color: #ffffff;
}
::selection
{
	background: rgba(255,163,123,1);
	color: #ffffff;
}
p::-moz-selection
{
	
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Raleway', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
	color: #393939;
	font-weight: 600;
}
h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection
{
	
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
img
{
	max-width: 100%;
}
button:active
{
	outline: none;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	transition: all 200ms ease;
}
.trans_300
{
	transition: all 300ms ease;
}
.trans_400
{
	transition: all 400ms ease;
}
.trans_500
{
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.owl-carousel,
.owl-carousel .owl-stage-outer,
.owl-carousel .owl-stage,
.owl-carousel .owl-item
{
	height: 100%;
}
.slide
{
	height: 100%;
}

/*********************************
3. Header
*********************************/

.header
{
	overflow: hidden;
	background: rgba(0,0,0,0.7) !important;
	letter-spacing: 0.1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	transition: all 400ms ease;
}
.logo
{
	border: solid 2px #ffffff;
	text-align: center;
}
.logo a
{
	display: block;
	font-size: 24px;
	font-weight: 600;
	color: #ffffff;
	line-height: 37px;
}
.main_nav ul li:not(:last-of-type)
{
	margin-right: 49px;
}
.main_nav ul li a
{
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active
{
	color: #1ABC9C;
}
.navbar-dark .navbar-nav .nav-link{
	color: rgba(255,255,255,.9)
}
.book_button
{
	width: 152px;
	height: 54px;
	background: #1ABC9C;
	color: #ffffff !important;
	margin-left: 45px;
	text-align: center;
	transition: all 200ms ease;
}
.book_button:hover
{
	background: #0f6c59;
}
.book_button:hover a
{
	color: #fff;
}
.book_button a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 54px;
	font-size: 14px;
	font-weight: 500;
	color: #ffffff !important;
}
.header_phone
{
	width: 190px;
	height: 54px;
	background: #1ABC9C;
	margin-left: 14px;
}
.header_phone span
{
	font-size: 16px;
	font-weight: 500;
	color: #ffffff;
	margin-left: 9px;
}
.header_phone span::-moz-selection
{
	background: #ffffff;
	color: #1ABC9C;
}
.header_phone span::selection
{
	background: #ffffff;
	color: #1ABC9C;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	background: rgba(255,255,255,0.93);
	visibility: hidden;
	opacity: 0;
}
.menu.active
{
	visibility: visible;
	opacity: 1;
}
.menu_content
{
	padding-right: 30px;
	padding-top: 170px;
}
.menu_close
{
	position: absolute;
	top: 50px;
	right: 30px;
	cursor: pointer;
	z-index: 1;
}
.menu_close i
{
	font-size: 24px;
	color: #121212;
}
.menu_close:hover i
{
	color: #1ABC9C;
}
.menu_nav ul li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.menu_nav ul li a
{
	font-weight: 700;
	font-size: 36px;
	color: #121212;
	line-height: 1.2;
}
.menu_nav ul li a:hover
{
	color: #1ABC9C;
}
.menu_nav ul li a span
{
	color: #690772;
}
.menu_extra
{
	right: 30px;
}
.menu_book
{
	display: none;
	margin-top: 50px;
}
.menu_book a
{
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-weight: 500;
	color: rgba(0,0,0,0.5);
}
.menu_book a::after
{
	display: block;
	position: absolute;
	bottom: 1px;
	left: 0;
	width: 100%;
	height: 1px;
	background: rgba(0,0,0,0.62);
	content: '';
	transition: all 200ms ease;
}
.menu_book a:hover
{
	color: #1ABC9C;
}
.menu_book a:hover::after
{
	background: #1ABC9C;
}
.menu_phone
{
	margin-top: 10px;
}
.menu_phone span
{
	font-size: 16px;
	font-weight: 500;
	color: rgba(0,0,0,0.5);
	margin-left: 9px;
}

/*********************************
5. Home
*********************************/

.home
{width: 100%;
	height: 480px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
@media (min-width: 768px) {
	.home
	{
		width: 100%;
		height: 100vh;
	}
}
.home_slider_container
{
	width: 100%;
	height: 100%;
}
.home_slider .owl-dots
{
	display: none;
}
.home_slider_dots_container
{
	position: absolute;
	left: 61px;
	bottom: 39px;
	z-index: 1;
}
.home_slider_custom_dots li
{
	font-size: 14px;
	color: #ffffff;
	cursor: pointer;
	transition: all 200ms ease;
}
.home_slider_custom_dots li:not(:last-of-type)
{
	margin-right: 5px;
}
.home_slider_custom_dots li.active,
.home_slider_custom_dots li:hover
{
	color: #1ABC9C;
}
.home_slider_op
{
	z-index: 2;
	background-color: rgba(0,0,0, .5);
}
/* .home_slider .background_image
{
	opacity: 0.05;
} */
.home_container
{
	position: absolute;
	top: 36.5%;
	left: 0;
	width: 100%;
	z-index: 3;
}
.home_title
{
	font-size: 110px;
	font-weight: 600;
	color: #ffffff;
	line-height: 1.2;
}
.booking_form_container
{
	width: 100%;
	margin-top: 34px;
	padding-left: 78px;
	padding-right: 88px;
}
.booking_form
{
	display: block;
	width: 100%;
}
.booking_input
{
	width: 100%;
	height: 54px;
	background: rgba(255,255,255,0.2);
	border: solid 2px #ffffff;
	padding-left: 1rem;
	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
	outline: none;
}
.booking_input_container
{
	width: 100%;
}
.booking_input_container > div
{
	padding-right: 10px;
}
.booking_input_container > div:first-child,
.booking_input_container > div:nth-child(2)
{
	width: 32.60869565217391%;
}
.booking_input_container > div:nth-child(3),
.booking_input_container > div:nth-child(4)
{
	width: 17.39130434782609%;
}
.booking_input_a
{
	
}
.booking_input_b
{
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
{ 
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
}
.booking_input::-webkit-input-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #FFFFFF !important;
}
.booking_input:-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #FFFFFF !important;
}
.booking_input::-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #FFFFFF !important;
} 
.booking_input:-ms-input-placeholder
{ 
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #FFFFFF !important;
}
.booking_input::input-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #FFFFFF !important;
}
.booking_button
{
	width: 152px;
	height: 54px;
	background: #1ABC9C;
	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
	border: none;
	outline: none;
	cursor: pointer;
}
.booking_button:hover
{
	background: rgba(255,255,255,0.2);
}

/*********************************
6. Features
*********************************/

.features
{
	background: #ffffff;
	padding-top: 115px;
	padding-bottom: 24px;
}
.icon_box_col
{
	margin-bottom: 80px;
}
.icon_box_icon
{
	width: 82px;
	height: 82px;
}
.icon_box_icon img
{
	max-width: 100%;
	max-height: 100%;
}
.icon_box_icon svg
{
	max-width: 100%;
	max-height: 100%;
}
.svg path, .svg rect, .svg polygon
{
    fill: #a1a1a1;
    transition: all 200ms ease;
}
.icon_box:hover .svg path,
.icon_box:hover .svg rect,
.icon_box:hover .svg polygon
{
	fill: #1ABC9C;
}
.icon_box_title
{
	margin-top: 16px;
}
.icon_box_title h2
{
	
}
.icon_box_text
{
	margin-top: 21px;
}

/*********************************
7. Gallery
*********************************/

.gallery
{
	width: 100%;
	/* background: #f0f0f0; */

}
.gallery_item
{
	height: calc(100vw / 4);
}
.gallery_item a
{
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
}

/*********************************
8. About
*********************************/

.about
{
	background: #ffffff;
}
.about_text
{
	margin-top: 29px;
}
.about_images
{
	height: 100%;
	padding-left: 17px;
	padding-right: 19px;
}

/*********************************
9. Testimonials
*********************************/

.testimonials
{
	padding-top: 117px;
	padding-bottom: 114px;
}
.testimonials_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(13,11,24,0.79);
	pointer-events: none;
}
.test_slider_item
{
	background: #ffffff;
	padding-top: 43px;
	padding-bottom: 58px;
	padding-left: 45px;
	padding-right: 45px;
}
.rating i::before
{
	font-family: 'FontAwesome';
	content: "\f005";
	font-style: normal;
	font-size: 18px;
	color: rgba(0,0,0,0.2);
}
.rating i:not(:last-of-type)
{
	margin-right: 6px;
}
.rating_1 i:first-child::before{color: #ffb400;}
.rating_2 i:first-child::before{color: #ffb400;}
.rating_2 i:nth-child(2)::before{color: #ffb400;}
.rating_3 i:first-child::before{color: #ffb400;}
.rating_3 i:nth-child(2)::before{color: #ffb400;}
.rating_3 i:nth-child(3)::before{color: #ffb400;}
.rating_4 i:first-child::before{color: #ffb400;}
.rating_4 i:nth-child(2)::before{color: #ffb400;}
.rating_4 i:nth-child(3)::before{color: #ffb400;}
.rating_4 i:nth-child(4)::before{color: #ffb400;}
.rating_5 i:first-child::before{color: #ffb400;}
.rating_5 i:nth-child(2)::before{color: #ffb400;}
.rating_5 i:nth-child(3)::before{color: #ffb400;}
.rating_5 i:nth-child(4)::before{color: #ffb400;}
.rating_5 i:nth-child(5)::before{color: #ffb400;}
.testimonial_title
{
	margin-top: 21px;
}
.testimonial_title a
{
	font-size: 24px;
	font-weight: 600;
	color: #393939;
	line-height: 1.2;
}
.testimonial_title a:hover
{
	color: #1ABC9C;
}
.testimonial_text
{
	margin-top: 24px;
}
.testimonial_image
{
	width: 106px;
	height: 106px;
	border-radius: 50%;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
	margin-top: 12px;
}
.testimonial_author
{
	margin-top: 26px;
	font-size: 15px;
	font-weight: 600;
	color: #393939;
}
.testimonial_author a
{
	font-size: 15px;
	font-weight: 600;
	color: #393939;
}
.testimonial_author a:hover
{
	color: #1ABC9C;
}

/*********************************
10. Booking
*********************************/

.booking
{
	background: #ffffff;

}
.booking_text
{
	margin-top: 28px;
}
.booking_slider_container
{
	margin-top: 63px;
}
.booking_item
{
	height: 569px;
}
.booking_price
{
	position: absolute;
	top: 15px;
	left: 15px;
	width: 128px;
	height: 36px;
	background: #ffffff;
	text-align: center;
	line-height: 36px;
	font-size: 16px;
	font-weight: 600;
	color: #393939;
}
.booking_link
{
	position: absolute;
	bottom: 18px;
	left: 15px;
	width: calc(100% - 30px);
	height: 65px;
	background: #1ABC9C;
	text-align: center;
}
.booking_link p
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 65px;
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}
.booking_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(83, 76, 76, 0.29);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
}
.booking_item:hover .booking_overlay
{
	visibility: visible;
	opacity: 1;
}

/*********************************
11. Blog
*********************************/

.blog
{

}
.blog_slider_container
{
	width: 100%;
	height: 487px;
}
.blog_slide
{
	height: 100%;
	background: #0f0a27;
}
.blog_slide .background_image
{
	opacity: 0.33;
}
.blog_content
{
	position: absolute;
	left: 52px;
	bottom: 57px;
	width: calc(100% - 52px);
	padding-right: 30px;
}
.blog_date
{
	width: 116px;
	height: 26px;
	background: #1ABC9C;
	text-align: center;
}
.blog_date a
{
	display: block;
	height: 100%;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	line-height: 26px;
}
.blog_title
{
	margin-top: 14px;
}
.blog_title a
{
	font-size: 36px;
	font-weight: 600;
	color: #ffffff;
	line-height: 1.2;
}
.blog_title a:hover
{
	color: #1ABC9C;
}

/*********************************
12. Footer
*********************************/

.footer-copyright {
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgb(14, 113, 93);
  text-align: center;
  font-size: 0.9rem;
}
.footer-copyright p {
	color: rgba(255, 255, 255, 0.8); 
}

.footer
{
	background: #ffffff;
	border-top: solid 2px #eaf2f7;
	padding-top: 81px;
}
/* .footer_content
{
	padding-bottom: 52px;
} */
.footer_logo
{
	display: inline-block;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
.footer_logo > div:first-of-type
{
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	border: solid 3px #393939;
	font-size: 43.32px;
	font-weight: 600;
	color: #393939;
	line-height: 68px;
}
.footer_logo > div:last-of-type
{
	font-size: 16px;
	font-weight: 600;
	color: #393939;
	line-height: 0.75;
	margin-top: 15px;
}
.footer_logo a
{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.footer_row
{
	margin-top: 74px;
}
.footer_title
{
	font-size: 18px;
	font-weight: 700;
	color: #393939;
	line-height: 1.2;
}
.footer_list
{
	margin-top: 9px;
}
.footer_list ul li
{
	font-size: 15px;
	font-weight: 500;
	color: #6d6d6d;
	line-height: 2;
}
.newsletter_container
{
	margin-top: 8px;
}
.newsletter_form
{
	display: block;
	position: relative;
}
.newsletter_input
{
	width: 100%;
	height: 64px;
	background: #ffffff;
	border: none;
	border-bottom: solid 2px #393939;
	outline: none;
	font-size: 15px;
	font-weight: 600;
	color: #393939;
}
.newsletter_input::-webkit-input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.newsletter_input:-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.newsletter_input::-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
} 
.newsletter_input:-ms-input-placeholder
{ 
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.newsletter_input::input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.newsletter_button
{
	width: 152px;
	height: 54px;
	background: #1ABC9C;
	margin-top: 23px;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
}
.certificates
{
	padding-left: 12px;
	padding-top: 10px;
}
.cert
{
	margin-bottom: 30px;
}
.map {
	margin-top: 2rem;
	margin-bottom: 2rem;
	height: 400px;
}
.copyright
{
	width: 100%;
	background: #ffffff;
	text-align: center;
	line-height: 33px;
	font-size: 15px;
	font-weight: 500;
	color: #6d6d6d;
}


/***********
13. Responsive
***********/

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.header_phone
	{
		width: 175px;
		height: 52px;
	}
	.book_button
	{
		width: 132px;
		height: 52px;
		margin-left: 25px;
	}
	.book_button a
	{
		line-height: 52px;
	}
	.main_nav ul li:not(:last-of-type)
	{
		margin-right: 30px;
	}
	.booking_form > div > div:last-child
	{
		margin-left: auto;
		margin-right: auto;
		margin-top: 15px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.home_title
	{
		font-size: 72px;
	}
	.booking_input_container > div
	{
		padding-right: 0;
	}
	.booking_input_container > div:first-child,
	.booking_input_container > div:nth-child(2)
	{
		width: 100%;
	}
	.booking_input_container > div:nth-child(3),
	.booking_input_container > div:nth-child(4)
	{
		width: 100%;
	}
	.booking_input_container > div:not(:last-of-type)
	{
		margin-bottom: 10px;
	}
	.about_images
	{
		margin-top: 42px;
	}
	.gallery_item
	{
		height: calc(100vw / 3);
	}
	.blog_title a
	{
		font-size: 30px;
	}
	.footer_row > div[class^='col']:not(:last-child)
	{
		margin-bottom: 60px;
	}
	.footer_content
	{
		padding-bottom: 22px;
	}
	.cert:not(:last-child)
	{
		margin-right: 15px;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{

	.header_content
	{
		padding-left: 30px;
		padding-right: 30px;
	}
	.home_title
	{
		font-size: 56px;
	}
	.home_slider_dots_container
	{
		left: 30px;
	}
	.book_button
	{
		display: none;
	}
	.header_phone
	{
		display: none !important;
	}
	.menu_book
	{
		display: block;
	}
	.booking_form_container
	{
		padding-left: 0;
		padding-right: 0;
	}
	.gallery_item
	{
		height: calc(100vw / 2);
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	h2
	{
		font-size: 24px;
	}
	p
	{
		font-size: 14px;
	}
	.header_content
	{
		padding-left: 15px;
		padding-right: 15px;
		height: 90px;
	}
	.header.scrolled .header_content
	{
		height: 70px;
	}
	.logo
	{
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		width: auto;
		height: auto;
	}
	.logo a
	{
		font-size: 18px;
		line-height: 32px;
	}
	.home_title
	{
		font-size: 40px;
	}
	.booking_input
	{
		height: 40px;
	}
	.home_container
	{
		top: 55%;
		transform: translateY(-50%);
	}
	.booking_button
	{
		width: 125px;
		height: 44px;
	}
	.home_slider_dots_container
	{
		display: none;
	}
	.icon_box_icon
	{
		width: 60px;
		height: 60px;
	}
	.gallery_item
	{
		height: calc(100vw - 15px);
	}
	.blog_content
	{
		left: 30px;
		bottom: 30px;
		width: calc(100% - 30px);
	}
	.blog_title a
	{
		font-size: 24px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}
.style_home__2j9f6
{
	width: 100%;
	height: 480px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.style_home_title__3NsRr
{
	font-size: 72px !important;
	font-weight: 600;
	color: #ffffff;
	line-height: 1.2;
}

.style_contact_map__Yj3_T
{
	width: 100%;
	height: calc(100vh - 90px)
}
.style_map__3sW2b
{
	width: 100%;
	height: calc(100vh - 90px);
	overflow: hidden;
}
.style_google_map__3NVZW
{
	width: 100%;
	height: calc(100vh - 90px);
}
.style_map_container__11AmO
{
	width: 100%;
	height: calc(100vh - 90px);
	/* overflow: hidden; */
}
#style_map__3sW2b
{
	width: 100%;
	height: calc(100vh -90px);
}

/*********************************
6. Contact
*********************************/

.contact
{
	margin-top: 90px;
	background: #ffffff;
	border-bottom: solid 2px #eaf2f7;
}
.contact_content
{
	padding-top: 105px;
	padding-bottom: 114px;
}
.contact_list
{
	margin-top: 58px;
}
.contact_list ul li
{
	font-size: 16px;
	font-weight: 500;
	color: #605d5d;
}
.contact_list ul li:not(:last-of-type)
{
	margin-bottom: 17px;
}
.contact_form_container
{
	margin-top: 73px;
}
.contact_form
{
	display: block;
	position: relative;
}
.input_container
{
	margin-bottom: 5px;
}
.contact_input
{
	width: 100%;
	height: 64px;
	background: #ffffff;
	border: none;
	outline: none;
	border-bottom: solid 2px #393939;
	font-size: 16px;
	font-weight: 500;
	color: #393939;
}
.contact_input::-webkit-input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input:-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input::-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
} 
.contact_input:-ms-input-placeholder
{ 
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input::input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_textarea
{
	height: 129px;
	padding-top: 15px;
}
.contact_button
{
	margin-top: 31px;
	width: 152px;
	height: 54px;
	background: #1abc9c;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	transition: all 200ms ease;
}
.contact_button:hover
{
	box-shadow: 0px 10px 23px rgba(0,0,0,0.15);
}

.style_home__3joKF
{
	width: 100%;
	height: 480px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.style_home_title__2151F
{
	font-size: 72px !important;
	font-weight: 600;
	color: #ffffff;
	line-height: 1.2;
}

.style_booking_title__2637j{
	margin-top: 90px;
}


.booking
{
	background: #ffffff;
	/* padding-top: 114px;
	padding-bottom: 114px; */
}


.booking_slider_container
{
	
}
.booking_item
{
	height: 569px;
}
.booking_price
{
	position: absolute;
	top: 15px;
	left: 15px;
	width: 128px;
	height: 36px;
	background: #ffffff;
	text-align: center;
	line-height: 36px;
	font-size: 16px;
	font-weight: 600;
	color: #393939;
}
.booking_link
{
	position: absolute;
	bottom: 18px;
	left: 15px;
	width: calc(100% - 30px);
	height: 65px;
	background: #1ABC9C;
	text-align: center;
	cursor: pointer;
}
.booking_link p
{
	display: block;
	height: 100%;
	line-height: 65px;
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}
.booking_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background:  rgba(83, 76, 76, 0.29);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
}
.booking_item:hover .booking_overlay
{
	visibility: visible;
	opacity: 1;
}
.booking_item_content
{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: 42px;
	padding-right: 20px;
	background: transparent;
	padding-bottom: 114px;
	visibility: hidden;
	opacity: 0;
	transform: translateY(30px);
	transition: all 800ms ease;
}
.booking_item:hover .booking_item_content
{
	visibility: visible;
	opacity: 1;
	transform: none;
}
.booking_item_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.booking_item_list ul li
{
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}

/*********************************
7. Details
*********************************/

.details
{
	background: #ffffff;
}
.details:not(:last-child)
{
	margin-bottom: 73px;
}
.details_image
{
	height: 100%;
	min-height: 400px;
}
.details_content
{
	padding-top: 21px;
	padding-bottom: 24px;
}
.details_title
{
	font-size: 36px;
	font-weight: 600;
	color: #393939;
}
.details_list
{
	margin-top: 30px;
}
.details_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.details_list ul li
{
	font-size: 18px;
	font-weight: 600;
	color: #393939;
}
.details_long_list
{
	margin-top: 33px;
}
.details_long_list ul
{
	margin-left: -3px;
}
.details_long_list ul li::before
{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 4px;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background: #6d6d6d;
	content: '';
	vertical-align: middle;
	font-size: 15px;
}
.details_long_list ul li
{
	position: relative;
	font-size: 15px;
	font-weight: 400;
	color: #6d6d6d;
	padding-left: 12px;
	line-height: 2;
}
.book_now_button
{
	width: 152px;
	height: 54px;
	background: #1ABC9C;
	text-align: center;
	margin-top: 26px;
	transition: all 200ms ease;
}
.book_now_button:hover
{
	box-shadow: 0px 10px 23px rgba(0,0,0,0.15);
}
.book_now_button a
{
	display: block;
	height: 100%;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	line-height: 54px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

