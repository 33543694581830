/*********************************
6. Contact
*********************************/

.contact
{
	margin-top: 90px;
	background: #ffffff;
	border-bottom: solid 2px #eaf2f7;
}
.contact_content
{
	padding-top: 105px;
	padding-bottom: 114px;
}
.contact_list
{
	margin-top: 58px;
}
.contact_list ul li
{
	font-size: 16px;
	font-weight: 500;
	color: #605d5d;
}
.contact_list ul li:not(:last-of-type)
{
	margin-bottom: 17px;
}
.contact_form_container
{
	margin-top: 73px;
}
.contact_form
{
	display: block;
	position: relative;
}
.input_container
{
	margin-bottom: 5px;
}
.contact_input
{
	width: 100%;
	height: 64px;
	background: #ffffff;
	border: none;
	outline: none;
	border-bottom: solid 2px #393939;
	font-size: 16px;
	font-weight: 500;
	color: #393939;
}
.contact_input::-webkit-input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input:-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input::-moz-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
} 
.contact_input:-ms-input-placeholder
{ 
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_input::input-placeholder
{
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #6d6d6d !important;
}
.contact_textarea
{
	height: 129px;
	padding-top: 15px;
}
.contact_button
{
	margin-top: 31px;
	width: 152px;
	height: 54px;
	background: #1abc9c;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.contact_button:hover
{
	box-shadow: 0px 10px 23px rgba(0,0,0,0.15);
}
