.home
{
	width: 100%;
	height: 480px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.home_title
{
	font-size: 72px !important;
	font-weight: 600;
	color: #ffffff;
	line-height: 1.2;
}

.booking_title{
	margin-top: 90px;
}
