
.booking
{
	background: #ffffff;
	/* padding-top: 114px;
	padding-bottom: 114px; */
}


.booking_slider_container
{
	
}
.booking_item
{
	height: 569px;
}
.booking_price
{
	position: absolute;
	top: 15px;
	left: 15px;
	width: 128px;
	height: 36px;
	background: #ffffff;
	text-align: center;
	line-height: 36px;
	font-size: 16px;
	font-weight: 600;
	color: #393939;
}
.booking_link
{
	position: absolute;
	bottom: 18px;
	left: 15px;
	width: calc(100% - 30px);
	height: 65px;
	background: #1ABC9C;
	text-align: center;
	cursor: pointer;
}
.booking_link p
{
	display: block;
	height: 100%;
	line-height: 65px;
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}
.booking_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background:  rgba(83, 76, 76, 0.29);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
}
.booking_item:hover .booking_overlay
{
	visibility: visible;
	opacity: 1;
}
.booking_item_content
{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: 42px;
	padding-right: 20px;
	background: transparent;
	padding-bottom: 114px;
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translateY(30px);
	-moz-transform: translateY(30px);
	-ms-transform: translateY(30px);
	-o-transform: translateY(30px);
	transform: translateY(30px);
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.booking_item:hover .booking_item_content
{
	visibility: visible;
	opacity: 1;
	-webkit-transform: none;
	-moz-transform: none;
	-ms-transform: none;
	-o-transform: none;
	transform: none;
}
.booking_item_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.booking_item_list ul li
{
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
}

/*********************************
7. Details
*********************************/

.details
{
	background: #ffffff;
}
.details:not(:last-child)
{
	margin-bottom: 73px;
}
.details_image
{
	height: 100%;
	min-height: 400px;
}
.details_content
{
	padding-top: 21px;
	padding-bottom: 24px;
}
.details_title
{
	font-size: 36px;
	font-weight: 600;
	color: #393939;
}
.details_list
{
	margin-top: 30px;
}
.details_list ul li:not(:last-of-type)
{
	margin-bottom: 3px;
}
.details_list ul li
{
	font-size: 18px;
	font-weight: 600;
	color: #393939;
}
.details_long_list
{
	margin-top: 33px;
}
.details_long_list ul
{
	margin-left: -3px;
}
.details_long_list ul li::before
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 4px;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background: #6d6d6d;
	content: '';
	vertical-align: middle;
	font-size: 15px;
}
.details_long_list ul li
{
	position: relative;
	font-size: 15px;
	font-weight: 400;
	color: #6d6d6d;
	padding-left: 12px;
	line-height: 2;
}
.book_now_button
{
	width: 152px;
	height: 54px;
	background: #1ABC9C;
	text-align: center;
	margin-top: 26px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.book_now_button:hover
{
	box-shadow: 0px 10px 23px rgba(0,0,0,0.15);
}
.book_now_button a
{
	display: block;
	height: 100%;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	line-height: 54px;
}